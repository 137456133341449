import React, {useState} from "react";
import Button from "../Button/Button"
import "./ContactForm.css";
import { FileText } from "react-feather";
import {withRouter} from "react-router-dom";

const ContactForm = ({...props}) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const sendContact = () => { 
        console.log("hello");
        props.history.push(
            "/contact",
            {
                name: name,
                email: email,
                phone: phone
            }
        )
    }

    return (
        <div className="contact-form">
            <div className="contact-form--header">
                <div className="left">
                    <FileText size="48"/>
                </div>
                <div className="right">
                    <span>Need to know a price?</span>
                    <h2 style={{margin: 0, padding: 0}}>Request a quote.</h2>
                </div>
            </div>
            <div className="contact-form--body">
                <input type="text" value={name} onChange={event => setName(event.target.value)} placeholder="Full Name"/>
                <input type="text" value={email} onChange={event => setEmail(event.target.value)} placeholder="Email"/>
                <input type="text" value={phone} onChange={event => setPhone(event.target.value)} placeholder="Phone Number"/>
                <Button fullWidth onClick={sendContact}>Submit Request <img src="./assets/lightning-bolt.svg" style={{marginLeft: "1rem" }}/></Button>
            </div>
        </div>
    )
}

export default withRouter(ContactForm);