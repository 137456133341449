import React from "react";

import "./Layout.css";

function Layout({...props}) {
    return(
        <div className="layout--container">{props.children}</div>
    )
}

function Row({justify = "justify-start", wrap="wrap", align="align-start", className="", ...props}) {
    return(
        <div className={`layout--container__row ${justify} ${align} ${wrap} ${className}`} {...props}>{props.children}</div>
    )
}

function Column({flex = false, xs, sm, md, lg, xl, wrap="wrap", justify = "justify-start", align="align-start", ...props}) {
    return(
        <div 
            className={
            `layout--container__column 
            ${props.className}
                ${flex ? "flex" : ""} ${justify} ${align} ${wrap} ${xs ? `col-xs-${xs}` : ""} ${sm ? `col-sm-${sm}` : ""} ${md ? `col-md-${md}` : ""} ${lg ? `col-lg-${lg}` : ""} ${xl ? `col-xl-${xl}` : ""}`
            }
        >
                {props.children}
            </div>
    )
}

export {Layout, Row, Column}