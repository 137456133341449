import React, {useState} from "react";
import { Layout, Row, Column } from "../Layout/Layout";
import Flickity from 'react-flickity-component'

import "./AboutPage.css";

const flickityOptions = {
    initialIndex: 2,
    wrapAround: true,
    pageDots: false,
    arrowShape: { 
        x0: 10,
        x1: 60, y1: 50,
        x2: 65, y2: 45,
        x3: 20
    }

}

const AboutPage = () => {
    return(
        <div>
            <header className="bg-grey">
                <Layout>
                    <Row align="align-center">
                        <Column className="header--text-container">
                            <div className="header--text">
                                <h1 className="yellow">About Us</h1>
                                <h2 className="yellow">
                                We strive to remain on the cutting edge of the electrical contracting industry. 
                                Vue Electric is proud to offer its clients the best service available.
                                </h2>
                            </div>
                        </Column>
                        <Column className="services-content--image-container" flex="true" justify="justify-end" align="align-center">
                            <div className="about-content--header-image">
                                <img src="/assets/About-Us.jpg" />
                            </div>
                        </Column>
                    </Row>
                </Layout>
            </header>
            <div className="about-content">
                <Layout>
                    <Row align="align-start">
                        <Column className="about--section-title">
                            <h4 className="service-countdown"><span className="short-line" /> About Us</h4>
                        </Column>
                        <Column className="about--section-title">
                            <p className="about-content--about-text">
                                Vue Electric has over 30 years of experience within the residential 
                                and commercial electrical industry. Since then, we have become the premier 
                                electrical contractor for the East End of Long Island, serving all areas of 
                                the Hamptons and Shelter Island. We also provide services throughout Suffolk 
                                County and the North Fork areas of Long Island.
                            </p>
                            <p className="about-content--about-text">
                                Our practice specializes in a wide variety of projects including; construction, 
                                renovations, underground fault detection and repair, electrical repairs, 
                                non-invasive directional drilling, landscape lighting, and lighting design. We 
                                also provide design consulting services for projects requiring our industry 
                                expert experience. We take great pride in servicing our clients needs and work 
                                very hard to enhance your Vue.
                            </p>
                        </Column>
                    </Row>
                </Layout>
            </div>
            <div className="about-slider">
                <Flickity
                    className={'carousel'} // default ''
                    elementType={'div'} // default 'div'
                    options={flickityOptions} // takes flickity options {}
                    disableImagesLoaded={false} // default false
                >
                    <div className="slide">
                        <img src="https://images.pexels.com/photos/453201/pexels-photo-453201.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
                    </div>
                    <div className="slide">
                        <img src="https://images.pexels.com/photos/1082355/pexels-photo-1082355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
                    </div>
                    <div className="slide">
                        <img src="https://images.pexels.com/photos/584399/living-room-couch-interior-room-584399.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
                    </div>
                    <div className="slide">
                        <img src="./assets/pexels-photo-1027512.jpeg" />
                    </div>
                </Flickity>
            </div>
        </div>
    )
}

export default AboutPage;
