import React, { useEffect } from 'react';
import "./Button.css";

function Button({color = "#FFBE16", background="#000", fullWidth=false, ...props}) {
    
    return(
        <button style={{color: color, backgroundColor: background, width: fullWidth ? "100%" :  "auto", ...props.style}} onClick={props.onClick}>
            {props.children}
        </button>
    )
}

export default Button;