import React, {useState, useEffect} from "react";
import { withRouter, Link } from "react-router-dom";
import {Layout, Row, Column} from "../Layout/Layout";

import "./Navigation.css";

function Navigation({...props}) {
    const [scrollClass, setScrollClass] = useState("");
    const [menuOpen, setMenuOpen] = useState(false);
    const [pathname, setPathname] = useState("");


    useEffect(() => {
        // Mount
        window.addEventListener("scroll", handleScroll); 
               
        //Unmount
        return () => window.removeEventListener("scroll", handleScroll);
    },[]);

    useEffect(() => {
        setPathname(props.location.pathname);
    })

    useEffect(() => {
        
        if(props.location.pathname === "/services") {
            document.querySelector("nav").style.color="#FFF";
            if(menuOpen) {
                document.querySelector(".logo-svg").src="./assets/new-logo.svg";
            }
            else document.querySelector(".logo-svg").src="./assets/new-logo-white.svg";
            document.querySelector(".hamburger").classList.add("white")
        }
        
        else {
            document.querySelector("nav").style.color="var(--yellow-color)";
            document.querySelector(".logo-svg").src="./assets/new-logo.svg";
            document.querySelector(".hamburger").classList.remove("white")
        }

        setTimeout(handleScroll, 1);

    });
    
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        document.querySelector(".logo-svg").src="./assets/new-logo.svg";
    }

    const handleScroll = () => {

        if(window.scrollY > 100) {
            setScrollClass("bg-white");
            document.querySelector(".logo-svg").src="./assets/new-logo.svg";
            document.querySelector(".hamburger").classList.remove("white")
        }

        else setScrollClass("");
    }

    const close = () => {
        setMenuOpen(false);
    }

    return(
        <nav className={`${scrollClass} ${menuOpen ? " visible" : ""}`}>
            <Layout>
                <Row justify="justify-around">
                    <Column flex="true" align="align-center">
                        <Link to="/" className="logo" style={{display: "flex", alignItems: "center"}}>
                        <img src="./assets/new-logo.svg" className="logo-svg" />
                        </Link>
                    </Column>
                    <Column flex="true" align="align-center" justify="justify-end">
                        <Link to="/" className={pathname === "/" ? "selected" : ""}>Home</Link>
                        <Link to="/services" className={pathname === "/services" ? "selected" : ""}>Services</Link>
                        <Link to="/about" className={pathname === "/about" ? "selected" : ""}>About</Link>
                        <Link to="/contact" className={pathname === "/contact" ? "selected" : ""}>Contact</Link>
                        <div className="hamburger" onClick={toggleMenu} />
                    </Column>
                </Row>
            </Layout>
            <div className={`popup ${menuOpen ? " visible" : ""}`} onClick={close}>
                <Link to="/">Home</Link>
                <Link to="/services">Services</Link>
                <Link to="/about">About</Link>
                <Link to="/contact">Contact</Link>
            </div>
        </nav>
    )
}

export default withRouter(Navigation);