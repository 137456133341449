import React from "react";

import "./LightningIcon.css";

const LightningIcon = () => {
    return (
        <div className="lightning-bolt" style={{marginRight: "1rem", display: "inline-flex" }}>
            <img src="./assets/lightning-bolt.svg"/>

            {/* <span className="bolt top" />
            <span className="bolt left" />
            <span className="bolt bottom" />
            <span className="bolt right" /> */}
        </div>
    )
}

export default LightningIcon;