import React, { useState, useEffect, useRef } from "react";
import Flickity from 'react-flickity-component';

import "./PopUpSlideShow.css";

const flickityOptions = {
    initialIndex: 1,
    wrapAround: true,
    pageDots: false,
    setGallerySize: false,
    arrowShape: { 
        x0: 10,
        x1: 60, y1: 50,
        x2: 65, y2: 45,
        x3: 20
    }

}

const PopUpSlideShow = ({slideShow = "", setSlideShow}) => {
    const [slideShowOpen, setSlideShowOpen] = useState(false);
    const flickityRef = useRef(null);

    const images = {
        audio_video: [
            "/audio_video/AV_03.jpg",
            "/audio_video/AV_04.jpg",
            "/audio_video/AV_05.jpg"
        ],
        construction: [
            "/construction/Construction_08.jpg",
            "/construction/Construction_06.jpg",
            "/construction/Construction_07.jpg"
        ],
        design_consultancy: [
            "/design_consultancy/DC_01.jpeg",
            "/design_consultancy/DC_02.jpg",
            "/design_consultancy/DC_04.jpg"
        ],
        home_automation: [
            "/home_automation/HomeAuto_04.jpg",
            "/home_automation/HomeAuto_05.jpg",
            "/home_automation/HomeAuto_06.jpg"
        ],
        lighting_solutions: [
            "/lighting_solutions/Lighting_02.jpg",
            "/lighting_solutions/Lighting_03.jpg",
            "/lighting_solutions/Lighting_05.jpg"
        ],
        pool_wiring: [
            "/pool_wiring/Pool-Wiring_06.jpg",
            "/pool_wiring/Pool-Wiring_05.jpeg",
            "/pool_wiring/Pool-Wiring_03.jpg"

        ]
    }



    useEffect(() => {
        if(slideShow.length) {
            setSlideShowOpen(true);
        }

        else setSlideShowOpen(false);

        window.addEventListener("keydown", handleKeyPress);
        
        return () => window.addEventListener("keydown", handleKeyPress);
    });
    
    const handleKeyPress = (event) => {
        
        //close slideshow for ESC key
        if(event.keyCode === 27) return setSlideShow("");
        else return;
        
    }

    const getImages = (slideShow) => {
        if(!slideShow) return;
        return images[slideShow].map(image => <div className="service-slide" key={image}><img src={`/assets/services-images${image}`} alt={slideShow} /></div>)
    }
    
    return(
        <div className="pop-up-slide-show" open={slideShowOpen}>
            <div className="close-pop-up" onClick={() => {setSlideShow("")}} />
            <div className="background-trigger" onClick={() => {setSlideShow("")}} />
            <Flickity
                ref={flickityRef}
                className={'service-slider'} // default ''
                elementType={'div'} // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate={true}
            >
                {getImages(slideShow)}
            </Flickity>
        </div>
    )
}

export default PopUpSlideShow;