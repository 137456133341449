
import React, { useEffect, useRef } from "react";
import { withRouter } from 'react-router-dom';


function usePrevious(value) {
    
    const ref = useRef();
    
    useEffect(() => {
        ref.current = value;
    }) 

    return ref.current;
}


const ScrollToTop = (props) => {
    const { location } = props;
    
    const prevProps = usePrevious({location});

    useEffect(() => {
        if(!prevProps) return;
		if (location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		}
    }, [location]);
    
    return (
        <div></div>
    )
}

export default withRouter(ScrollToTop);