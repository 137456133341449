import React, { useState } from 'react';
import './App.css';
import Navigation from './Components/Navigation/Navigation';
import {Layout, Row, Column} from "./Components/Layout/Layout";
import Home from './Components/Home/Home';
import ServicesPage from "./Components/ServicesPage/ServicesPage";
import AboutPage from "./Components/AboutPage/AboutPage";
import Footer from "./Components/Footer/Footer";

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import ContactPage from './Components/ContactPage/ContactPage';


import ScrollToTop from "./Components/ScrollHook/ScrollHook";

const App = () => {
  return (
    <div id="vue-electric">
      <ScrollToTop />
      <Navigation />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/services" component={ServicesPage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/contact" component={ContactPage} />
      </Switch>
      <Footer />
    </div>
  )
}

export default App;
