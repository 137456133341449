import React, { useState, useEffect } from "react";
import { Layout, Column, Row } from "../Layout/Layout.js";
import PopUpSlideShow from "../PopUpSlideShow/PopUpSlideShow";
import LightningIcon from "../LightningIcon/LightningIcon";
import zenscroll from "zenscroll";

import "./ServicesPage.css";

const sectionMap = {
    "POOL_WIRING": ".pool-wiring",
    "CONSTRUCTION": ".construction",
    "LIGHTING_SOLUTIONS": ".lighting-solutions",
    "HOME_AUTO": ".home-auto",
    "DESIGN_CONSULT": ".design-consult",
    "AUDIO_VIDEO": ".audio-video"
}

const ServicesPage = ({...props}) => {
    const [slideShow, setSlideShow] = useState("");
    
    useEffect(() => {
        const { state } = props.location;
        
        if(state) {
            const { section } = state;
            scrollToSection(section)
        }

    }, []);

    useEffect(()=> {
        document.body.classList.add("services-page");
        return () => document.body.classList.remove("services-page");
    })

    const scrollToSection = (section) => {
        console.log(sectionMap[section])
        const el = document.querySelector(sectionMap[section]);
        zenscroll.to(el);
    }

    const Icon = () => <img src="./assets/lightning-bolt.svg" style={{marginRight: "1rem" }}/>;

    return(
        <div className="page-services">
            <PopUpSlideShow slideShow={slideShow} setSlideShow={setSlideShow} />
            <header className="bg-yellow">
                <Layout>
                    <Row align="align-center">
                        <Column className="header--text-container">
                            <div className="header--text">
                                <h1 className="white">Our Services</h1>
                                <h2 className="white">We specialize in a wide variety of electrical services for your home or business.</h2>
                            </div>
                        </Column>
                    </Row>
                </Layout>
            </header>
            <div className="services-content">
                <Layout>
                    <Row className="service-row pool-wiring" align="align-center">
                        <Column flex="true" className="services-content--image-container">
                            <div className="services-content--image">
                                <img src="/assets/PoolWiring_08.png" />
                            </div>
                        </Column>    
                        <Column className="services-content--container">
                            <h4 className="service-countdown">
                                <span className="short-line" />
                                01 of 06
                            </h4>
                            <h2 className="service-title">
                                <span>
                                    Pool Wiring
                                </span>
                            </h2>
                            <p className="services-content--text">
                                We provide electrical wiring for pool and spa areas. Services include lighting, pumps, and filtration systems.
                            </p>
                            <span className="services-content--see-more services-content--text" onClick={() => setSlideShow("pool_wiring")}><LightningIcon /> See more of our Pool Wiring projects</span>
                        </Column>    
                    </Row>
                    <Row className="service-row construction" align="align-center">
                        <Column className="services-content--container">
                            <h4 className="service-countdown">
                                <span className="short-line" />
                                02 of 06
                            </h4>
                            <h2 className="service-title">
                                <span>
                                    Construction
                                </span>
                            </h2>
                            <p className="services-content--text">
                                An in house team can provide necessary construction services to fit clients’ project needs, no matter the job.
                            </p>
                            <span className="services-content--see-more services-content--text" onClick={() => setSlideShow("construction")}><Icon /> See more of our Construction projects</span>
                        </Column>
                        <Column className="services-content--image-container" flex="true" justify="justify-end">
                            <div className="services-content--image">
                                <img src="/assets/Vue_Construction.jpg" />
                            </div>
                        </Column>        
                    </Row>
                    <Row className="service-row lighting-solutions" align="align-center">
                        <Column flex="true" className="services-content--image-container">
                            <div className="services-content--image">
                                <img src="/assets/Vue_LightingSolutions.jpg" />
                            </div>
                        </Column>    
                        <Column className="services-content--container">
                            <h4 className="service-countdown">
                                <span className="short-line" />
                                03 of 06
                            </h4>
                            <h2 className="service-title">
                                <span>
                                    Lighting Solutions
                                </span>
                            </h2>
                            <p className="services-content--text">
                                Our design team provide elegant interior and exterior lighting fixtures for homes and living spaces all throughout Eastern L.I.
                            </p>
                            <span className="services-content--see-more services-content--text" onClick={() => setSlideShow("lighting_solutions")}><Icon />See more of our Lighting Solutions</span>
                        </Column>    
                    </Row>
                    <Row className="service-row home-auto" align="align-center">
                        <Column className="services-content--container">
                            <h4 className="service-countdown">
                                <span className="short-line" />
                                04 of 06
                            </h4>
                            <h2 className="service-title">
                                <span>
                                    Home Automation Solutions
                                </span>
                            </h2>
                            <p className="services-content--text">
                                In an increasingly automated world, Vue can help keep you up to date with installation of 
                                automatic home lighting, temperature controls, and garage door systems. 
                            </p>
                            <span className="services-content--see-more services-content--text" onClick={() => setSlideShow("home_automation")}><Icon /> See more of our Home Automation projects</span>
                        </Column>
                        <Column flex="true" justify="justify-end" className="services-content--image-container">
                            <div className="services-content--image">
                                <img src="/assets/Home-Automation.jpg" />
                            </div>
                        </Column>        
                    </Row>
                    <Row className="service-row design-consult" align="align-center">
                        <Column flex="true" className="services-content--image-container">
                            <div className="services-content--image">
                                <img src="/assets/DC_04.jpg" />
                            </div>
                        </Column>    
                        <Column className="services-content--container">
                            <h4 className="service-countdown">
                                <span className="short-line" />
                                05 of 06
                            </h4>
                            <h2 className="service-title">
                                <span>
                                    Design Consultancy
                                </span>
                            </h2>
                            <p className="services-content--text">
                                Starting a project is no easy undertaking. Our design team is committed to 
                                helping create thoughtful solutions that fit any project’s specific challenges.  
                            </p>
                            <span className="services-content--see-more services-content--text" onClick={() => setSlideShow("design_consultancy")}><Icon /> See more of our Design Consultation projects</span>
                        </Column>    
                    </Row>
                    <Row className="service-row audio-video" align="align-center" id="service-av">
                        <Column className="services-content--container">
                            <h4 className="service-countdown">
                                <span className="short-line" />
                                06 of 06
                            </h4>
                            <h2 className="service-title">
                                <span>
                                    Audio / Video Solutions
                                </span>
                            </h2>
                            <p className="services-content--text">
                                We provide installation and setup of a wide variety of audio and video 
                                equipment including home theater setups and professional speaker systems.
                            </p>
                            <span className="services-content--see-more services-content--text" onClick={() => setSlideShow("audio_video")}><Icon /> See more of our Audio / Video projects</span>
                        </Column>
                        <Column flex="true" justify="justify-end" className="services-content--image-container">
                            <div className="services-content--image">
                                <img src="/assets/AV-Solutions.jpg" />
                            </div>
                        </Column>        
                    </Row>
                </Layout>
            </div>
        </div>
    )
}

export default ServicesPage;