import React from "react";
import {Layout, Row, Column} from "../Layout/Layout";
import ContactForm from "../ContactForm/ContactForm";
import Flickity from 'react-flickity-component';
import { Link } from "react-router-dom";
import "./Home.css";
import Button from "../Button/Button";
import { withRouter } from "react-router-dom";

const flickityOptions = {
  initialIndex: 2,
  wrapAround: true,
  pageDots: true,
  setGallerySize: true,
  arrowShape: { 
      x0: 10,
      x1: 60, y1: 50,
      x2: 65, y2: 45,
      x3: 20
  },
  autoPlay: 5000

}

const POOL_WIRING = "POOL_WIRING";
const CONSTRUCTION = "CONSTRUCTION";
const LIGHTING_SOLUTIONS = "LIGHTING_SOLUTIONS";
const HOME_AUTO = "HOME_AUTO";
const DESIGN_CONSULT = "DESIGN_CONSULT";
const AUDIO_VIDEO = "AUDIO_VIDEO";

const Home = ({ ...props}) => {

  const navigatePage = (page) => {
    props.history.push(
      "/services",
      {
          section: page
      }
  )
  }

  return(
    <div className="home">
      <div className="hero">
        <Layout>
          <Row align="align-center">
            <div className="intro-quote">
              <h1>Electrical services in the Hamptons, North Fork, and Shelter Island.</h1>
              <Button style={{marginTop: "2rem"}} onClick={() => props.history.push("/contact")}>Get in Touch</Button>
            </div>
          </Row>
          <ContactForm />
        </Layout>
      </div>
      <Layout>
        <div className="intro-quote right">
          <h1 style={{"paddingLeft": "4rem"}} className="yellow">What people are saying...</h1>
          <Flickity
            className={'testimonial-slider'} // default ''
            elementType={'div'} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
          >
            <div className="testimonial-slide">
              <h2>“Consulting, recommending, and installing lighting fixtures that are specifically well suited to display the art in my home is second nature for Vue Electric.”</h2>
            </div>
            <div className="testimonial-slide">
              <h2>“My architectural company has been involved in hundreds of construction projects and we have worked with Vue Electric with many of our highly regarded clients. In all instances, Joe and his team have been incredibly detailed oriented, responsive, and professional.”</h2>
            </div>
            <div className="testimonial-slide">
              <h2>“I have had the opportunity to work with Joe on numerous projects and have been impressed with his ability to manage teams and current knowledge in the electrical industry.”</h2>
            </div>
          </Flickity>
        </div>
      </Layout>
      <div className="grey-section">
        <Layout>
          <div className="icon-slider">
            <div className="icon-slider--container" onClick={() => navigatePage(POOL_WIRING)}>
              <div className="icon-card">
                <img src="./assets/pool-icon.svg" />
                <h2 className="card-title">Pool Wiring</h2>
                <p className="icon-description">
                  We provide electrical wiring for pool and spa areas. Services include lighting, pumps, and filtration systems.
                </p>
              </div>
            </div>
            <div className="icon-slider--container" onClick={() => navigatePage(CONSTRUCTION)}>
              <div className="icon-card">
                <img src="./assets/construction.svg" />
                <h2 className="card-title">Construction</h2>
                <p className="icon-description">
                  An in house team can provide necessary construction services to fit clients’ project needs, no matter the job.
                </p>
              </div>
            </div>
            <div className="icon-slider--container" onClick={() => navigatePage(LIGHTING_SOLUTIONS)}>
              <div className="icon-card">
                <img src="./assets/light-bulb.svg" />
                <h2 className="card-title">Lighting Solutions</h2>
                <p className="icon-description">
                  Our design team provide elegant interior and exterior lighting fixtures for homes and living spaces all throughout Eastern L.I.
                </p>
              </div>
            </div>
            <div className="icon-slider--container" onClick={() => navigatePage(HOME_AUTO)}>
              <div className="icon-card">
                <img src="./assets/smart-home.svg" />
                <h2 className="card-title">Home Automation Systems</h2>
                <p className="icon-description">
                  In an increasingly automated world, Vue can help keep you up to date with installation of 
                  automatic home lighting, temperature controls, and garage door systems.
                </p>
              </div>
            </div>
            <div className="icon-slider--container" onClick={() => navigatePage(DESIGN_CONSULT)}>
              <div className="icon-card">
                <img src="./assets/design.svg" />
                <h2 className="card-title">Design Consultancy</h2>
                <p className="icon-description">
                  Starting a project is no easy undertaking. Our design team is committed to 
                  helping create thoughtful solutions that fit any project’s specific challenges.
                </p>
              </div>
            </div>
            <div className="icon-slider--container" onClick={() => navigatePage(AUDIO_VIDEO)}>
              <div className="icon-card">
                <img src="./assets/audio-video.svg" />
                <h2 className="card-title">Audio / Video</h2>
                <p className="icon-description">
                  We provide installation and setup of a wide variety of audio and video 
                  equipment including home theater setups and professional speaker systems.
                </p>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </div>
  )
}

export default withRouter(Home);