import React, { useEffect, useState } from "react";
import { Layout, Row, Column } from "../Layout/Layout";
import TextareaAutosize from "react-autosize-textarea";
import { Send } from "react-feather";

import "./ContactPage.css";

const ContactPage = (props) => {
  const [contactFields, setContactFields] = useState({
    name: "",
    email: "",
    phone: "",
    reason: "default",
    message: "",
  });
  const defaultText = () => (
    <>
      Send Message <Send size={18} />
    </>
  );

  const [formErrors, setFormErrors] = useState({});
  const [areErrors, setAreErrors] = useState(false);
  const [buttonText, setButtonText] = useState(defaultText);
  const [disableButton, setDisableButton] = useState(false);
  useEffect(() => {
    const { state } = props.location;
    if (state) {
      setContactFields({ ...state });
    }
  }, []);

  const sendEmail = async () => {
    if (!validateForm()) return;
    setDisableButton(true);
    setButtonText("Sending...");
    console.log(contactFields);

    await fetch("https://vueapi.zein.in/contact", {
      method: "POST",
      body: JSON.stringify(contactFields),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .catch((error) => console.log(error))
      .then((response) => {
        console.log("Success:", JSON.stringify(response));
        setButtonText("We will respond shortly!");
      });

    setButtonText("We will respond shortly!");
  };

  const validateForm = () => {
    let errors = {
      name: [],
      email: [],
      phone: [],
      reason: [],
      message: [],
    };

    Object.keys(contactFields).forEach((el) => {
      if (contactFields[el].length === 0) {
        errors[el].error = "Field cannot be empty";
      }
    });

    console.log(errors);

    const checkErrors = Object.keys(errors).some((el) => {
      console.log(el, errors[el].error);
      return errors[el].error !== undefined;
    });

    console.log(checkErrors);
    setAreErrors(checkErrors);

    setFormErrors(errors);

    return !checkErrors;
  };

  return (
    <div className="page-contact">
      <header className="bg-white">
        <Layout>
          <Row align="align-center">
            <Column className="header--text-container">
              <div className="header--text">
                <h1 className="white">Let's talk!</h1>
                <h2 className="grey">
                  We'd love to hear about your project. Submit this form here
                  and we'll get back to you within 24 hours.
                </h2>
              </div>
            </Column>
          </Row>
        </Layout>
      </header>
      <div>
        <Layout>
          <div
            className={
              areErrors ? " visible errors-container" : "errors-container"
            }
          >
            <h4>Please check the following fields</h4>
            <ul>
              {Object.keys(formErrors).map((el) =>
                formErrors[el].error ? <li key={el}>{el}</li> : ""
              )}
            </ul>
          </div>
          <Row>
            <Column
              justify="justify-between"
              flex="true"
              wrap="wrap"
              className="ctact"
            >
              <input
                type="text"
                value={contactFields.name}
                onChange={(event) =>
                  setContactFields({
                    ...contactFields,
                    name: event.target.value,
                  })
                }
                name="name"
                placeholder="Your Name"
              />
              <input
                type="text"
                value={contactFields.email}
                onChange={(event) =>
                  setContactFields({
                    ...contactFields,
                    email: event.target.value,
                  })
                }
                placeholder="Email Address"
              />
              <input
                type="text"
                value={contactFields.phone}
                onChange={(event) =>
                  setContactFields({
                    ...contactFields,
                    phone: event.target.value,
                  })
                }
                placeholder="Phone Number"
              />
              <select
                defaultValue="default"
                value={contactFields.reason}
                onChange={(event) =>
                  setContactFields({
                    ...contactFields,
                    reason: event.target.value,
                  })
                }
                placeholder="Reason"
              >
                <option disabled value="default">
                  Choose From List
                </option>
                <option value="pool wiring">Pool Wiring</option>
                <option value="construction">Construction</option>
                <option value="lighting solutions">Lighting Solutions</option>
                <option value="home automation solutions">
                  Home Automation Solutions
                </option>
                <option value="design consultancy">Design Consultancy</option>
                <option value="auto / video">Audio / Video Solutions</option>
                <option value="other">Other</option>
              </select>
              <TextareaAutosize
                placeholder={"Message"}
                onChange={(event) =>
                  setContactFields({
                    ...contactFields,
                    message: event.target.value,
                  })
                }
              />
              <button
                className="button-send-form"
                disabled={disableButton}
                onClick={sendEmail}
              >
                {buttonText}
              </button>
            </Column>
            <Column></Column>
          </Row>
        </Layout>
      </div>
    </div>
  );
};

export default ContactPage;
